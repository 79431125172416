import {
    onMounted,
    onUnmounted
} from "vue";

// 获取滚动条的偏移量
function scrollHandler(dom, right) {
    let prevWidth = 1920


    const handler = () => {
        let innerWidth = window.innerWidth;
        if (innerWidth < 1500) {
            dom.style.display = 'none'
        } else {
            dom.style.display = 'block'
            let curRight = parseInt(right),
                diff = prevWidth - innerWidth,
                dealRight = curRight -= diff;

            if (dealRight > 140) {
                dom.style.right = 140 + 'px'
            } else if (dealRight < 50) {
                dom.style.right = 50 + 'px'
            } else {
                dom.style.right = dealRight + 'px'
            }
        }
    }
    handler()
    return handler
}


export function useScroll(dom, right) {
    onMounted(() => {
        window.addEventListener('resize', scrollHandler(dom.value, right.value), false)
    })

}