

import http from '../axios'


// 公告
export const getnotice = (params) => {
   return http.post('/plat/notice', params)

}

// 公告详情
export const getTnoticeDetail = id => {
   return http.post('/content/info', { id, type: 6 })
}

// 公告相关
export const $getAboutData = () => {
   return http.post('/notice/website',)
}