import { defineStore } from 'pinia'
import selectFrom from '../utils/getRandomNum'
const instnace = selectFrom(0, 18) // 1 -10 之间的随机数
const imageArr = []
for (let i = 1; i <= 18; i++) {
    imageArr.push(require(`@/assets/背景/${i}.jpg`))
}
export const useCompanyBanner = defineStore('companyBanner', {
    state: () => {
        return {
            loaded: {}, // 已经加载的公司banner
            loading: false, // 是否正在初始化
            error: false,// 是否初始化失败
            id: null,// 当前展示公司的 banner id 是不是仅仅展示banner就靠这个字段进行计算
        }
    },
    getters: {
        ONLY_BANNER() {
            // 存图片的数组 0-9 是需要文字和标题的， 剩下的是不需要的
            if (this.id >= 10) {
                return true
            } else {
                return false
            }
        }
    },
    /* 永久行存储 */
    persist: {
        enabled: true, // 开启存储
        // **strategies: 指定存储位置以及存储的变量都有哪些,该属性可以不写，不写的话默认是存储到sessionStorage里边，默认存储state里边的所有数据**
        strategies: [
            { storage: localStorage, paths: ['loaded'] }
            // storage 存储到哪里 sessionStorage/localStorage
            // paths是一个数组,要存储缓存的变量,当然也可以写多个
            // paths如果不写就默认存储state里边的所有数据，如果写了就存储指定的变量
        ]
    },
    actions: {
        // 初始化公司的 banner 如果这家公司没有banner的情况下，有的话就不用初始化了
        initCompanyBanner(companyId) {
            if (this.loading) return
            if (!this.loaded[companyId]) {
                this.loading = true
                const index = instnace.get()
                this.loaded[companyId] = {
                    id: index,
                    img: imageArr[index]
                }
                this.id = index
                return imageArr[index]
            } else {
                this.id = this.loaded[companyId].id
                return this.loaded[companyId].img
            }
        },
    }
})
