import {
    onMounted,
    onUnmounted,
    ref
} from "vue"

export const useImage = () => {
    const marginLeft = ref('0px')
    onMounted(() => {
        handler()
        window.addEventListener('resize', handler)
    })
    const handler = () => {
        let width = window.innerWidth;
        if (width > 1920) {
            marginLeft.value = 0
            return
        }
        const marginLeft1 = 1920 - width
        if (marginLeft1 > 670) {
            marginLeft.value = -670 + 'px'

        } else {
            marginLeft.value = -marginLeft1 + 'px'
        }
    }
    onUnmounted(() => {
        window.removeEventListener('resize', handler)
    })
    return marginLeft
}