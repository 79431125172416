

import { defineStore } from 'pinia'
import { $getSteelDynamicData } from '@/api/modules/steel-dynamic.js'
export const useSteelDynamic = defineStore('steelDynamic', {
    state() {
        return {
            steelDynamicList: [], // 钢市动态列表
            params: {  // 钢市动态查询参数
                info: '',//关键词搜索
                start: '', // 开始时间
                end: '',//结束时间
            },
            column_id: '0',
            REQ: false,
            loading: false, // loading
            total: 0, // 总数
        }
    },
    actions: {
        async getSteelDynamicData(params) {
            this.loading = true
            params = {
                type: this.column_id,
                ...this.params,
                ...params,
            }
            let res = await $getSteelDynamicData(params)
            let { status, data } = res
            if (status) {
                this.steelDynamicList = data.content
                this.loading = false
                this.total = data.total
            }
        }
    }
})