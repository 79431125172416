import {
    ref,
    onMounted,
    onBeforeUnmount
} from 'vue'
import _ from 'lodash'
import { nextTick } from 'process';
export const useScroll = (scrollContainer) => {
    let heigth;
    let len;
    let time = ref(null)
    function changePosition() {
        if (scrollContainer.value?.children.length) {
            const firstCildrenNode = scrollContainer.value?.children[0];
            scrollContainer.value.appendChild(firstCildrenNode)
        }
    }
    time.value = setInterval(() => {
        changePosition()
    }, 2000)
    return time
}